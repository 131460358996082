<template lang="pug">
canvas#canvas.absolute.full(ref="canvas")
.container(v-show="fgVisible")
  nav#navbar.fixed
    .container.relative
      .logo-wrapper.absolute.full-h
        img.logo(src="~@/assets/line-wave/logo.png")
      ul.anchors
        -
          const anchors = [
            { text: 'ZEKU' },
            { text: 'ABOUT US' },
            { text: 'JOIN US' },
            { text: 'CONTACT' }
          ]
        each anchor, i in anchors
          li.anchor.relative(class=`color-${i} ${i === 0 ? 'active' : ''}`)
            .text= anchor.text
            .indicator.absolute
  main.main.fixed(@click="onContentClick" :data-mode="contentMode")
    template(v-if="contentMode === 0")
      h1.title CORE OF CONVERGENCE
      .footer.absolute
        .arrow-down
          img.icon.arrow(src="~@/assets/line-wave/arrow_down.svg")
    template(v-else)
      img.mask.absolute(src="~@/assets/line-wave/mask.svg")
      .body.relative
        .content
          h1.title.relative(v-show="contentMode === 1") CORE OF #[br]CONVERGENCE
          p.desc.relative A state-of-the-art semiconductor system provider to #[br] flourish the era of Internet Fusion.
        .footer
          .arrow-down
            img.icon.arrow(src="~@/assets/line-wave/arrow_down.svg")
</template>

<script>
import createREGL from 'regl';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { createLineWave } from '@/commands/line-wave';

import { useRoute } from 'vue-router';
import { windowSize } from '@zhinan-oppo/scroll-handle';

export default {
  name: 'Lines',
  setup() {
    const canvas = ref(null);

    const canvasOnResize = () => {
      const dpr = window.devicePixelRatio;
      const { width, height } = canvas.value.getBoundingClientRect();
      canvas.value.width = width * dpr;
      canvas.value.height = height * dpr;
    };

    onMounted(() => {
      windowSize.addSizeListener(canvasOnResize);
      canvasOnResize();

      const regl = createREGL({
        canvas: canvas.value,
        extensions: ['ANGLE_instanced_arrays'],
      });

      const { run } = createLineWave(regl);
      regl.frame(run);

      onBeforeUnmount(() => regl.destroy());
    });

    const route = useRoute();
    const fgVisible = computed(() => route.query['fg-hidden'] == undefined);

    const contentMode = ref(1);
    const onContentClick = () => {
      contentMode.value = (contentMode.value + 1) % 2;
    };

    return { contentMode, onContentClick, fgVisible, canvas };
  },
};
</script>

<style lang="stylus" scoped>
$green = #2DBE4D
$purple = #DC3C91
$red = #F50514
$orange = #FFAF35
*
  box-sizing border-box
#navbar
  z-index 100
  width 100%
  height 90px
  font-size 16px
  font-weight bold

#canvas
  width 100vw
  height 200vh

.container
  width 1312px
  height 100%
  margin 0 auto
  display flex
  align-items center
  justify-content flex-end
  @media (max-width: 1800px)
    width 1048px

.logo-wrapper
  display flex
  align-items center
  justify-content center
.logo
  width 32px
  height @width

.anchors, .anchor
  display flex
  align-items center

.anchor
  line-height 30px
  cursor pointer
  transition color .2s
  overflow hidden
  &:not(:first-child)
    margin-left 60px

.indicator
  width 100%
  height 2px
  left 0
  top auto
  bottom 0
  transition transform .2s
  transform translateX(-101%)

for $c, $i in $green $purple $red $orange
  .color-{$i}
    .indicator
      background $c
    &:hover, &.active
      color $c
      .indicator
        transform translateX(0)

.main
  z-index 99
  color #151413
  width 100%
  height 100%
  display flex
  justify-content center
  align-items center
  flex-direction column
  cursor pointer
  padding 0 304px
  user-select none
  @media (max-width: 1800px)
    padding 0 196px

  .body
    width 100%
    display flex
    align-items flex-end
    transform translateY(50%)
    .content
      display flex
      flex-direction column
      justify-content flex-start
      text-align start
    .desc
      font-size 26px
      line-height (40 / 26)
      margin (20 / 26)em 0
      @media (max-width: 1800px)
        font-size 24px
    .footer
      padding 0 60px 0
  .mask
    width 1200px
    height 896px
    top auto
    bottom 0
    left 0

  .title
    font-size 88px
    margin 0
    display flex
    justify-content center
    align-items center
    flex-direction column
    width 100%
    /[data-mode="1"]&
      align-items flex-start
      text-align start
      font-size 100px
    @media (max-width: 1800px)
      font-size 70px
      /[data-mode="1"]&
        font-size 78px
  .footer
    display flex
    align-items center
    justify-content center
    width 100%
    top auto
    bottom 100px
    padding 0 304px
    /[data-mode="1"]&
      justify-content flex-end
    @media (max-width: 1800px)
      bottom 80px
      padding 0 196px
  .arrow-down
    cursor default
    border 1px solid #000
    border-radius 50%
    display flex
    align-items center
    justify-content center
    width 124px
    height 124px
    @media (max-width: 1800px)
      width 120px
      height @width
    .icon
      width (32px / 124px * 100%)
      height @width
      @keyframes arrow-down
        from
          transform translateY(-20%)
        to
          transform translateY(40%)
      animation-name arrow-down
      animation-duration 1s
      animation-direction alternate
      animation-iteration-count infinite
      animation-timing-function ease-out
</style>
