/* eslint-disable no-unsafe-finally */
'use strict';
var __read =
  (this && this.__read) ||
  function(o, n) {
    var m = typeof Symbol === 'function' && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o),
      r,
      ar = [],
      e;
    try {
      while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    } catch (error) {
      e = { error: error };
    } finally {
      try {
        if (r && !r.done && (m = i['return'])) m.call(i);
      } finally {
        if (e) throw e.error;
      }
    }
    return ar;
  };
var __spread =
  (this && this.__spread) ||
  function() {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
  };
exports.__esModule = true;
exports.addBoundaries = void 0;
/**
 * When drawing a line, each segment is specified using 4 consecutive points: p0, p1, p2, p3.
 * A line segment is drawn only through p1 --- p2, but p0 and p3 are important for
 * segment endings. If p0 === p1, a cap will be drawn on the start, and if p2 === p3
 * a cap will be drawn on the end. Otherwise, an appropriate "accordion" will be computed.
 *
 * Given a line specified by points (p0, p1, ..., pn) generate boundary conditions:
 * - closed: pn, p0, p1, ..., pn, p0, p1
 * - open: p0, p0, p1, ..., pn, pn
 *
 * Or, if fill specified, use that instead:
 * - closed: fill, p0, p1, ..., pn, fill, fill
 * - open: fill, p0, p1, ..., pn, fill
 *
 * @param points
 * @param closed
 * @param fill
 */
function addBoundaries(points, closed, fill) {
  var _a, _b, _c;
  if (!Array.isArray(points) || points.length === 0) {
    return [];
  }
  if (closed) {
    return __spread(
      [
        (_a = fill !== null && fill !== void 0 ? fill : points[points.length - 1]) !== null &&
        _a !== void 0
          ? _a
          : points[0],
      ],
      points,
      [
        fill !== null && fill !== void 0 ? fill : points[0],
        (_b = fill !== null && fill !== void 0 ? fill : points[1]) !== null && _b !== void 0
          ? _b
          : points[0],
      ],
    );
  } else {
    return __spread([fill !== null && fill !== void 0 ? fill : points[0]], points, [
      (_c = fill !== null && fill !== void 0 ? fill : points[points.length - 1]) !== null &&
      _c !== void 0
        ? _c
        : points[0],
    ]);
  }
}
exports.addBoundaries = addBoundaries;
