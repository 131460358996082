import { createLines, CapType, JoinType } from 'regl-insta-lines';
import mat4 from 'gl-mat4';

import declarationsGLSL from './declarations.vert';
import defineVerticesGLSL from './offset.vert';
import cameraTransformGLSL from './camera.vert';
import mainEndGLSL from './mainEnd.vert';
import fragLine from './line.frag';
import circleVert from './circle.vert';
import circleFrag from './circle.frag';

import { addBoundaries } from './addBoundaries.js';

const N_POINTS_PER_LINE = 30;
const N_GROUP_LINES_MOVING = 2;
const T_MOVING = (N_GROUP_LINES_MOVING / 2) * 4;
const X_LINE_LEFT = -1.5;
const X_LINE_RIGHT = 1.5;
const POINT_ORIGIN = [1, 0, 0];
const ROTATION_MAX = Math.PI / 6;
const SPEED_POINT_MAX = 0.25;

function getRandom(arr, n) {
  const result = new Array(n);
  let len = arr.length;
  const taken = new Array(len);
  if (n > len) throw new RangeError('getRandom: more elements taken than available');
  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

/**
 * @typedef {[number, number, number]} Point
 * @param {Line} line
 * @param {number} n
 * @param {object} options
 * @param {number|() => number} options.speed
 * @param {number} options.color
 * @param {[number, number]} options.range
 * @param {number} options.probabilityGreen
 * @typedef {{ point: Point; movement: number; color: number; }} Circle
 * @return { Circle[] }
 */
function getRandomCirclesFromLine(
  line,
  n = 2,
  { speed, size, color, probabilityGreen = 0.6, range = [0, 1] } = {},
) {
  const len = line.points.length;
  return getRandom(
    line.points.slice(Math.floor(len * range[0]), Math.floor(len * range[1])),
    n,
  ).map((point) => ({
    point,
    movement: line.movement,
    color: color ?? (Math.random() >= 1 - probabilityGreen ? 1 : Math.floor(Math.random() * 3 + 2)),
    rotation: line.rotation,
    speed:
      speed != undefined
        ? typeof speed === 'function'
          ? speed()
          : speed
        : (Math.random() * 0.8 + 0.2) * SPEED_POINT_MAX,
    size: size ?? 10,
  }));
}

function getPointsOfLine(n = 10, { z = Math.random() * 0.01 } = {}) {
  const points = [];
  for (let x = X_LINE_LEFT; x <= X_LINE_RIGHT; x += 1 / n) {
    points.push([x, 0, z]);
  }
  return points;
}

/**
 * @typedef {{ points: Point[]; color: number; movement: number; closed: boolean; rotation: number; }} Line
 * @param {number} n
 * @param {object} param1
 * @returns {Line[]}
 */
function getLines(n, { color, movement = Math.random() + 1, rotation }) {
  return Array.from(Array(n)).map((_, i) => {
    const points = getPointsOfLine(N_POINTS_PER_LINE);

    return {
      points,
      color,
      rotation:
        (rotation != undefined
          ? typeof rotation === 'function'
            ? rotation()
            : rotation
          : (Math.random() - 0.5) * ROTATION_MAX) + 0.2,
      movement: typeof movement === 'function' ? movement(i) : movement,
      closed: false,
    };
  });
}

/**
 *
 * @param {import('regl').Regl} regl
 * @param {Line[]} lines
 */
function createLineCommand(regl, lines) {
  /** @type {import('regl-insta-lines').CreateLinesOptions<3>} */
  const commonOptions = {
    dimension: 3,
    width: 2, // in pixels
    cap: CapType.round,
    join: JoinType.round, // specify limit here
    joinCount: 2,
    blend: {
      enable: true,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
        // srcRGB: 'src alpha',
        // srcAlpha: 'src alpha',
        // dstRGB: 'one minus src alpha',
        // dstAlpha: 'dst alpha',
      },
    },
    // primitive: 'lines',
    // cameraTransform: ,
    declarationsGLSL,
    defineVerticesGLSL,
    cameraTransformGLSL,
    mainEndGLSL,
  };
  const lineCtx = createLines(regl, { ...commonOptions, frag: fragLine });
  lineCtx.setLines(lines.map(({ points, closed }) => ({ points, closed })));

  const colors = lines.flatMap((line) =>
    addBoundaries(
      line.points.map(() => line.color),
      line.closed,
      line.color,
    ),
  );
  const movements = lines.flatMap((line) =>
    addBoundaries(
      line.points.map(() => line.movement),
      line.closed,
      line.movement,
    ),
  );
  const rotations = lines.flatMap((line) =>
    addBoundaries(
      line.points.map(() => line.rotation),
      line.closed,
      line.rotation,
    ),
  );

  const cmd = regl({
    attributes: {
      aColor: {
        buffer: regl.buffer({ type: 'float32' })(colors),
        divisor: 1,
        offset: Float32Array.BYTES_PER_ELEMENT * 0,
        stride: Float32Array.BYTES_PER_ELEMENT,
      },
      aMovement: {
        buffer: regl.buffer({ type: 'float32' })(movements),
        divisor: 1,
        offset: Float32Array.BYTES_PER_ELEMENT * 0,
        stride: Float32Array.BYTES_PER_ELEMENT,
      },
      aRotation: {
        buffer: regl.buffer({ type: 'float32' })(rotations),
        divisor: 1,
        offset: Float32Array.BYTES_PER_ELEMENT * 0,
        stride: Float32Array.BYTES_PER_ELEMENT,
      },
    },
  });

  return () => {
    cmd(() => {
      lineCtx.render();
    });
  };
}

/**
 *
 * @param {import('regl').Regl} regl
 * @param {Circle[]} circles
 */
function createCircleCommand(regl, circles) {
  const cmd = regl({
    attributes: {
      aPoint: regl.buffer(circles.map(({ point }) => point)),
      aColor: regl.buffer(circles.map(({ color }) => color)),
      aMovement: regl.buffer(circles.map(({ movement }) => movement)),
      aRotation: regl.buffer(circles.map(({ rotation }) => rotation)),
      aSpeed: regl.buffer(circles.map(({ speed }) => speed)),
      aSize: regl.buffer(circles.map(({ size }) => size)),
    },
    primitive: 'points',
    frag: circleFrag,
    vert: circleVert,
    count: circles.length,
  });
  return cmd;
}

/**
 *
 * @param {import('regl').Regl} regl
 * @returns
 */
export function createLineWave(regl) {
  const linesGreyStayed = getLines(16, { color: 0 });
  const linesColorfulStayed = [
    { rotation: (0.45 + 0.05 * Math.random()) * -ROTATION_MAX },
    { rotation: (0.15 + 0.05 * Math.random()) * -ROTATION_MAX },
    { rotation: (0.15 + 0.05 * Math.random()) * ROTATION_MAX },
    { rotation: (0.45 + 0.05 * Math.random()) * ROTATION_MAX },
    { rotation: 0, color: 1 },
  ].flatMap(({ rotation, color }, i) => getLines(1, { color: color ?? i + 1, rotation }));

  const linesColorfulMoving = Array.from(Array(N_GROUP_LINES_MOVING))
    .flatMap(() => [3, 4, 1, 2])
    .flatMap((color, i) =>
      getLines(1, {
        color,
        movement: () => -Math.random() * i - 0.3 * i,
        rotation: () => (i / (N_GROUP_LINES_MOVING * 4) - 0.5) * ROTATION_MAX,
      }),
    );

  const lines = [...linesGreyStayed, ...linesColorfulMoving, ...linesColorfulStayed];
  const renderLines = createLineCommand(regl, lines);

  /** @type {Circle[]} */
  const circlesStayed = [
    ...linesGreyStayed.flatMap((line) =>
      getRandomCirclesFromLine(line, 4 * N_GROUP_LINES_MOVING, { speed: undefined }),
    ),
    ...linesColorfulStayed.flatMap((line) =>
      getRandomCirclesFromLine(line, 3 * N_GROUP_LINES_MOVING, { speed: undefined }),
    ),
    ...linesColorfulStayed.flatMap((line, i) =>
      getRandomCirclesFromLine(line, 2 * N_GROUP_LINES_MOVING, {
        speed: () => Math.random() * SPEED_POINT_MAX + 1 * SPEED_POINT_MAX,
        size: 30,
        range: [0, 1],
        color: line.color,
      }),
    ),
    // ...linesColorfulStayed.flatMap((line, i) =>
    //   getRandomCirclesFromLine(line, 2, {
    //     speed: () => ((Math.random() * 2) / 3) * SPEED_POINT_MAX + 0.05,
    //     size: 30,
    //     range: i >= 4 ? [1 / 4, 3 / 4] : [3 / 4, 4 / 5],
    //     color: line.color,
    //   }),
    // ),
  ];
  const renderCircles = createCircleCommand(regl, circlesStayed);

  const cmd = regl({
    uniforms: {
      time: regl.context('time'),
      movingT: T_MOVING,
      xLineLeft: X_LINE_LEFT,
      xLineRight: X_LINE_RIGHT,
      origin: POINT_ORIGIN,
      rotateFirst: 0,
      model: mat4.translate([], mat4.fromScaling([], [1, 0.5, 1]), [0, 0.25, 0]),
      view: ({ time }) => {
        // const eye = [0, -0.2 * Math.sin(time), 3 * Math.cos(time)];
        const eye = [0, -0.26, 3];
        return mat4.lookAt([], eye, [0, eye[1], 0], [0.1, 1, 0]);
      },
      projection: ({ viewportWidth, viewportHeight }) =>
        mat4.perspective([], Math.PI / 8, viewportWidth / (viewportHeight / 2), 0.1, 100),
    },
    attributes: {},
    depth: { enable: false },
  });
  const run =
    /** @param {import('regl').DefaultContext} ctx */
    () => {
      cmd(() => {
        renderLines();
        renderCircles();
      });
    };

  return { run };
}
